import {setup, breakpoints} from '@w11r/use-breakpoint'

const config = {
  fromMicro: 0,
  fromSmall: 600,
  fromMedium: 1000,
  fromLarge: 1200,
  fromWide: 1500,
  fromTall: 500
}

export const mediaQueries = {
  fromMicro: `@media (min-width: ${config.fromMicro}px)`,
  fromSmall: `@media (min-width: ${config.fromSmall}px)`,
  fromMedium: `@media (min-width: ${config.fromMedium}px)`,
  fromLarge: `@media (min-width: ${config.fromLarge}px)`,
  fromWide: `@media (min-width: ${config.fromWide}px)`,
  fromTall: `@media (min-height: ${config.fromTall}px)`
}

setup({
  breakpoints: {
    ...breakpoints,
    micro: [config.fromMicro, config.fromSmall],
    small: [config.fromSmall, config.fromMedium],
    medium: [config.fromMedium, config.fromLarge],
    large: [config.fromLarge, config.fromWide],
    wide: [config.fromWide, 10000]
  }
})

export default config
