const {
  REACT_APP_AUTH_COOKIE_DOMAIN,
  REACT_APP_AUTH_REDIRECT_ENDPOINT,
  REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_AUTH_USER_POOL_ID,
  REACT_APP_AUTH_COGNITO_DOMAIN
} = process.env

type AuthConfig = {
  cookieDomain: string
  redirectEndpoint: string
  userPoolWebClientId: string
  userPoolId: string
  cognitoDomain: string
}

export const authConfig: AuthConfig = {
  cookieDomain: REACT_APP_AUTH_COOKIE_DOMAIN || '',
  redirectEndpoint: REACT_APP_AUTH_REDIRECT_ENDPOINT || '',
  userPoolWebClientId: REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID || '',
  userPoolId: REACT_APP_AUTH_USER_POOL_ID || '',
  cognitoDomain: REACT_APP_AUTH_COGNITO_DOMAIN || ''
}
