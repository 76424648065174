import {createGlobalStyle} from 'styled-components'

import breakpoints from './constants/breakpoints'

const GlobalStyle = createGlobalStyle`
  html {
    background: url('/images/floor-tile.svg') rgb(193, 202, 220) repeat;
    background-size: 180px 180px;
    font-size: 16px;

    @media (min-width: ${breakpoints.fromSmall}px) {
      font-size: 14px;
    }

    @media (min-width: ${breakpoints.fromMedium}px) {
      font-size: 16px;
    }
  }

  body {
    background: none;
    margin: 0px;
    padding: 0px;
    font-family: 'Roboto Slab', sans-serif;
  }

  h1, h2, h3 {
    font-family: 'Fortis', serif;
    font-weight: normal;
  }
`

export default GlobalStyle
