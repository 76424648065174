import React from 'react'
import ReactDOM from 'react-dom'
import {Normalize} from 'styled-normalize'
import 'bootstrap/dist/css/bootstrap.min.css'

import App from './App'
import GlobalStyle from './styles'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <React.StrictMode>
    <Normalize />
    <GlobalStyle />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
