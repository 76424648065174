import React from 'react'
import loadable from '@loadable/component'
import styled, {css} from 'styled-components'
import {QueryCache, ReactQueryCacheProvider} from 'react-query'
import {BaseModalBackground, ModalProvider} from 'styled-react-modal'
import {AuthProtected} from 'web-common-react-ts'

import {fadeIn, fadeOut} from './constants/animations'
import {authConfig} from './constants/auth'

const queryCache = new QueryCache()

const transitionEasing = 'cubic-bezier(.22,.68,0,1.71)'

const ModalBackground = styled(BaseModalBackground)<{isClosing: number}>`
  animation: ${fadeIn} 1s ${transitionEasing} forwards;

  ${(props) =>
    props.isClosing
      ? css`
          animation: ${fadeOut} 2s ${transitionEasing} forwards;
        `
      : ''}
`

const Routes = loadable(() => import(/* webpackChunkName: "home" */ './Routes'))

function App() {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ModalProvider backgroundComponent={ModalBackground}>
        <AuthProtected
          cookieDomain={authConfig.cookieDomain}
          redirectEndpoint={authConfig.redirectEndpoint}
          userPoolWebClientId={authConfig.userPoolWebClientId}
          userPoolId={authConfig.userPoolId}
          cognitoDomain={authConfig.cognitoDomain}
        >
          <Routes />
        </AuthProtected>
      </ModalProvider>
    </ReactQueryCacheProvider>
  )
}

export default App
